














































































import { reactive, toRefs, ref, computed, watch, onUnmounted } from '@vue/composition-api';
import { useUserState, useUserActions } from '@/store';
import { copyText } from 'vue3-clipboard';

export default {
  name: 'ModulePresets',
  components: {
    MVideoask: () => import('@/components/molecules/m-videoask.vue')
  },
  props: {
    value: {
      required: true,
      type: Object
    },
    fetchProgram: {
      required: true,
      type: [Object, Function]
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    }
  },
  setup(props: any, ctx) {
    const setup = reactive({});
    const { user }: any = useUserState(['user']);
    const { updateAdkData } = useUserActions(['updateAdkData']);
    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const index = programDoc.value?.data?.adks?.findIndex(obj => {
      if (obj) {
        return obj.name === props.adkType;
      }
      return false;
    });
    const fieldIndex = programDoc.value?.data?.adks[index]?.presetFields?.findIndex(field => {
      if (field.name) {
        return field.name === 'm-welcome-adk';
      }
      return false;
    });
    const videoAskUrl = computed(() => {
      return `https://www.videoask.com/f9ifm2r3p#contact_name=${encodeURI(
        user.value.firstName
      )}&contact_email=${encodeURI(user.value.email)}&program_id=${encodeURI(
        programDoc.value.data._id.toString()
      )}`;
    });
    const isVideoaskResponseAdded: any = computed(() => {
      return !!programDoc.value?.data?.adks[index]?.completed;
    });
    const isFetching = ref(false);
    async function fetchStatus() {
      isFetching.value = true;
      try {
        const setFunction = setInterval(() => {
          props.fetchProgram();
          if (
            programDoc.value?.data?.adks[index]?.presetFields[fieldIndex]?.setup?.status ===
            'complete_setup'
          ) {
            clearInterval(setFunction);
            isVideoaskResponseAdded.value = true;
            isFetching.value = false;
          }
        }, 10000);
        setTimeout(() => {
          isFetching.value = false;
        }, 2000);
      } catch (error) {
        console.log(error);
        isFetching.value = false;
      }
    }

    const redoWelcome = ref(false);
    const isLoading = ref(false);
    async function process() {
      isLoading.value = true;
      setTimeout(async () => {
        delete programDoc.value?.data?.adks[index]?.presetFields[fieldIndex]?.setup
          ?.signInVideoAskResponse;
        delete programDoc.value?.data?.adks[index]?.presetFields[fieldIndex]?.setup?.completed;
        await updateAdkData({
          program_id: programDoc.value.data._id.toString(),
          data: programDoc.value?.data?.adks[index]
        });
        props.fetchProgram();
        isLoading.value = false;
        redoWelcome.value = false;
      }, 2000);
    }
    watch(isVideoaskResponseAdded, val => {
      if (val) {
        fetchStatus();
      }
    });
    const intervalId = window.setInterval(() => {
      if (props.value?.data?.adks[index]?.completed) {
        clearInterval(intervalId);
      } else {
        props.fetchProgram();
      }
    }, 10000);

    onUnmounted(() => {
      clearInterval(intervalId);
    });
    const driveLink = ref();
    if (programDoc.value?.data?.adks[index]?.driveLink) {
      driveLink.value = programDoc.value?.data?.adks[index]?.driveLink;
    }
    const isVerifyLink = ref(false);
    async function verifyLink() {
      isVerifyLink.value = true;
      programDoc.value.data.adks[index].presetFields[fieldIndex].setup.driveLink = driveLink.value;
      await updateAdkData({
        program_id: programDoc.value.data._id.toString(),
        data: programDoc.value?.data?.adks[index]
      });
      props.fetchProgram();
      setTimeout(() => {
        isVerifyLink.value = false;
      }, 2000);
    }
    const copied = ref(false);
    const doCopy = () => {
      copyText(driveLink.value, undefined, error => {
        if (error) {
          console.log(error);
        } else {
          copied.value = true;
          setTimeout(() => {
            copied.value = false;
          }, 5000);
        }
      });
    };
    return {
      ...toRefs(setup),
      redoWelcome,
      programDoc,
      user,
      index,
      driveLink,
      fetchStatus,
      isVideoaskResponseAdded,
      process,
      verifyLink,
      isVerifyLink,
      isFetching,
      isLoading,
      doCopy,
      copied,
      videoAskUrl,
      fieldIndex
    };
  }
};
