var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"module-edit pa-0"},[_c('div',{staticStyle:{"width":"100%"}},[_c('MVideoask',{attrs:{"videoask-response":(_vm.programDoc.data.adks[_vm.index] && _vm.programDoc.data.adks[_vm.index].signInVideoAskResponse) ||
        null,"videoask-label":"Once done with welcome, wait 1-minute, then click submit","videoask-url":_vm.videoAskUrl},on:{"fetchStatus":_vm.fetchStatus,"deleteVideo":_vm.process}}),_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('div',{staticClass:"d-flex flex-column pa-12"},[_c('div',{staticClass:"d-flex justify-center mb-4"},[_c('h3',[_vm._v("Insert get started folder (Recommended)")])]),_c('div',[_c('validation-provider',{attrs:{"rules":{
              regex: /^((?:https?:)?\/\/)?((?:www)\.)?((?:(.*)\.com))(.*)/,
              required: true
            },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"justify-center align-center mb-4",attrs:{"error-messages":errors,"label":"Enter link to shared drive (ie: Google Drive)","x-large":"","rounded":"","outlined":"","type":"url","hint":"Please make sure it is editable by anyone who views"},model:{value:(_vm.driveLink),callback:function ($$v) {_vm.driveLink=$$v},expression:"driveLink"}},[(!_vm.copied)?_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""},on:{"click":_vm.doCopy}},on),[(!invalid)?_c('v-icon',[_vm._v("mdi-content-copy")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Copy content")])]):_c('v-tooltip',{attrs:{"slot":"append","top":""},slot:"append",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-auto mb-auto",attrs:{"icon":""}},on),[(!invalid)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Link copied")])])],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"welcome__full-width-btn font-weight-bold",attrs:{"disabled":invalid,"rounded":"","dark":!invalid,"color":"yellow","x-large":"","depressed":"","loading":_vm.isVerifyLink},on:{"click":function($event){return _vm.verifyLink()}}},[_vm._v("Verify & Save Link")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }